import api from "../lib/api";

export const getSiteSettings = async () => {
  try {
    const res = await api.get(`/settings`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getMenus = async () => {
  try {
    const res = await api.get(`/menus`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getSlide = async (keySlide) => {
  try {
    const res = await api.get(`/slide/${keySlide}`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getBanner = async (keyBanner) => {
  try {
    const res = await api.get(`/banner/${keyBanner}`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getPage = async (keyPage) => {
  try {
    const res = await api.get(`/page/${keyPage}`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
};
export const saveNewsletter = async (data) => {
  try {
    const res = await api.post(`/newsletter`, data);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const sendContactForm = async (data) => {
  try {
    const res = await api.post(`/contact`, data);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const saveReminder = async (data) => {
  try {
    const res = await api.post(`/reminder`, data);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

// Auth
export const userLogin = async (data) => {
  const res = await api.post(`/login`, data);
  if (res.data.status === true) {
    return res.data;
  }
};

export const register = async (data) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const res = await api.post(`/register`, data, config);
  if (res.data.status === true) {
    return res.data;
  }
};

export const checkAuth = async () => {
  try {
    const res = await api.get(`/customer-informations`);

    if (res.data.status === true) {
      return { userData: res.data.data };
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};
export const claimCredits = async () => {
  const res = await api.post(`/credits/claim`);

  if (res.data.status === true) {
    return res.data.data;
  }
};
export const requestPasswordReset = async (data) => {
  try {
    const res = await api.post(`/forgot-password`, data);
    if (res.data.status === true) {
      return { message: "success" };
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};
export const resetUserPassword = async (data) => {
  try {
    const res = await api.post(`/reset-password`, data);
    if (res.data.status === true) {
      return { message: "success" };
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};
export const editUserProfileInfo = async (data) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await api.post(
      `/customer-update`,
      {
        avatar: data.avatar,
        fname: data.fname,
        lname: data.lname,
        email: data.email,
        type: data.type,
        mobile: data.mobile,
      },
      config
    );
    if (res.data.status === true) {
      return { userData: res.data.data, message: "success" };
    }
  } catch (error) {
    // console.log('editUserProfileInfo Error => ', error?.response?.data?.message)
    return error?.response?.data?.message;
  }
};
export const changeUserPassword = async (data) => {
  try {
    const res = await api.post(`/customer-update-password`, {
      old_password: data.old_password,
      password: data.password,
      password_confirmation: data.password_confirmation,
    });
    if (res.data.status === true) {
      return { message: "success" };
    }
  } catch (error) {
    // console.log('changeUserPassword Error => ', error?.response?.data?.message)
    return error?.response?.data?.message;
  }
};

// Categories
export const getAllCategories = async () => {
  try {
    const res = await api.get(`/all-categories`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getStructuredCategories = async () => {
  try {
    const res = await api.get(`/structured-categories`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getFeaturedCategories = async () => {
  try {
    const res = await api.get(`/featured-categories`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

// Brands
export const getAllBrands = async () => {
  try {
    const res = await api.get(`/all-brands`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getFeaturedBrands = async () => {
  try {
    const res = await api.get(`/featured-brands`);
    // console.log(res.data.data)
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

// Products
export const getBestOffers = async () => {
  try {
    const res = await api.get(`/best-offers`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getBestSellers = async () => {
  try {
    const res = await api.get(`/best-sellers`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getArrivalProducts = async () => {
  try {
    const res = await api.get(`/arrival-products`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getSingleProduct = async (keyPage) => {
  try {
    const res = await api.get(`/product/${keyPage}`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};

export const getBlogs = async (params) => {
  try {
    const res = await api.get(`/blogs-feed`, {
      params,
    });
    // if (res.data.status === true) {
    return res.data;
    // }
  } catch (error) {
    return error?.response?.data;
  }
};

export const getAllBlogs = async (params) => {
  try {
    const res = await api.get(`/blogs`, {
      params,
    });
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
};

export const getBlogCategories = async () => {
  try {
    const res = await api.get(`/blogs/categories`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
};

export const submitBlog = async (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await api.post(`/blogs/submit`, data, config);
  if (res.data.status === true) {
    return res.data.blog;
  }
};

export const getSingleBlog = async (slug) => {
  try {
    const res = await api.get(`/blogs/${slug}`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};

export const getProducts = async (params) => {
  try {
    const res = await api.get(`/products`, {
      params,
    });
    if (res.data.status === true) {
      return res.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};
export const getSearchProducts = async (params) => {
  try {
    const res = await api.get(`/products-search`, {
      params,
    });
    if (res.data.status === true) {
      return res.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};
export const getFilterCategories = async () => {
  try {
    const res = await api.get(`/filter-categories`);
    if (res.data.status === true) {
      return res.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};
export const getFilterBrands = async () => {
  try {
    const res = await api.get(`/filter-brands`);
    if (res.data.status === true) {
      return res.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};
export const getFilterMeasures = async () => {
  try {
    const res = await api.get(`/filter-measures`);
    if (res.data.status === true) {
      return res.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};
export const getFilterProperties = async () => {
  try {
    const res = await api.get(`/filter-properties`);
    if (res.data.status === true) {
      return res.data;
    }
  } catch (error) {
    // throw new Error(error);
    return error?.response?.data;
  }
};

// Location
export const getCountries = async () => {
  try {
    const res = await api.get(`/countries`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getCities = async (keyCountry) => {
  try {
    const res = await api.get(`/cities/${keyCountry}`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

// ADDRESSE
export const getAddresses = async () => {
  try {
    const res = await api.get(`/addresses`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};
export const addAddress = async (data) => {
  try {
    const res = await api.post(`/add-address`, {
      line_1: data.line_1,
      line_2: data.line_2,
      type: data.type,
      country: data.country,
      city: data.city,
      is_default: data.is_default,
    });
    if (res.data.status === true) {
      return { data: res.data.data, message: "success" };
    }
  } catch (error) {
    // console.log('addAddress Error => ', error?.response?.data?.message)
    return error?.response?.data?.message;
  }
};
export const editAddress = async (data, keyAddress) => {
  try {
    const res = await api.post(`/update-address/${keyAddress}`, {
      line_1: data.line_1,
      line_2: data.line_2,
      type: data.type,
      country: data.country,
      city: data.city,
      is_default: data.is_default,
    });
    if (res.data.status === true) {
      return { data: res.data.data, message: "success" };
    }
  } catch (error) {
    // console.log('editAddress Error => ', error?.response?.data?.message)
    return error?.response?.data?.message;
  }
};
export const removeAddress = async (keyAddress) => {
  try {
    const res = await api.get(`/remove-address/${keyAddress}`);
    if (res.data.status === true) {
      return { data: res.data.data, message: "success" };
    }
  } catch (error) {
    // console.log('removeAddress Error => ', error?.response?.data?.message)
    return error?.response?.data?.message;
  }
};

// MY ORDERS
export const getOrders = async () => {
  try {
    const res = await api.get(`/orders`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};
export const getInvoices = async () => {
  try {
    const res = await api.get(`/invoices`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};
export const getBls = async () => {
  try {
    const res = await api.get(`/bls`);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};
export const getOrder = async (data) => {
  try {
    const res = await api.post(`/order`, data);
    if (res.data.status === true) {
      return { data: res.data.data, message: "success" };
    }
  } catch (error) {
    return error?.response?.data?.message;
  }
};

// WISHLIST
export const getWishlistItems = async () => {
  const res = await api.get(`/wishlist`);
  if (res.data.status === true) {
    return res.data.data;
  }
};
export const addToWishlist = async (id) => {
  const res = await api.post(`/wishlist/store`, { product: id });

  if (res.data.status === true) {
    return res.data.data;
  }
};
export const removeFromWishlist = async (id) => {
  const res = await api.post(`/wishlist/remove`, { product: id });
  if (res.data.status === true) {
    return res.data.data;
  }
};
export const cleatWishlist = async () => {
  const res = await api.get(`/wishlist/clear`);
  if (res.data.status === true) {
    return res.data.data;
  }
};
export const getWishlistItemsGuest = async () => {
  const wishlist = JSON.parse(localStorage.getItem("ecowattWishlist"));

  if (wishlist && wishlist.length) {
    const res = await api.post(`/guest-wishlist`, {
      products: wishlist,
    });
    if (res.data.status === true) {
      return res.data.data;
    }
  }
  return [];
};

// CART
export const getCartItems = async () => {
  let cart = localStorage.getItem("ecowattCart");

  if (cart) cart = JSON.parse(cart);
  else cart = [];

  if (cart.length > 0) {
    await getCombineCartItems();
    localStorage.setItem("ecowattCart", JSON.stringify([]));
  }

  const res = await api.get(`/cart`);

  if (res.data.status === true) {
    return res.data.data;
  }
};
export const addToCart = async (data) => {
  const res = await api.post(`/cart/store`, {
    product: data?.id,
    quantity: data?.quantity,
  });

  if (res.data.status === true) {
    return res.data.data;
  }
};
export const updateToCart = async (data) => {
  const res = await api.post(`/cart/update`, {
    cart_id: data?.cart_id,
    product: data?.id,
    quantity: data?.quantity,
  });
  if (res.data.status === true) {
    return res.data.data;
  }
};
export const removeCart = async (data) => {
  const res = await api.post(`/cart/remove`, { cart_id: data });
  if (res.data.status === true) {
    return res.data.data;
  }
};

export const getCartItemsGuest = async () => {
  const cart = localStorage.getItem("ecowattCart");

  if (cart && cart.length) {
    const res = await api.post(`/guest-cart`, {
      products: cart,
    });
    if (res.data.status === true) {
      return res.data.data;
    }
  }
  if (cart && cart.length) {
    const res = await api.post(`/guest-cart`, {
      products: cart,
    });
    if (res.data.status === true) {
      return res.data.data;
    }
  }
};
export const getCombineCartItems = async () => {
  let cart = localStorage.getItem("ecowattCart");

  if (cart) cart = JSON.parse(cart);
  else cart = [];
  if (cart) cart = JSON.parse(cart);
  else cart = [];

  localStorage.setItem("ecowattCart", JSON.stringify([]));
  localStorage.setItem("ecowattCart", JSON.stringify([]));

  if (cart && cart.length) {
    const res = await api.post(`/cart/combine`, { products: cart });
    if (res.data.status === true) {
      return res.data.data;
    }
    return [];
  }
  return [];
};

// CHECKOUT
export const AuthCheckout = async (data) => {
  const cart = localStorage.getItem("ecowattCart");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  if (cart && cart.length) {
    const res = await api.post(`/checkout`, data, config);
    if (res.data.status === true) {
      return res.data;
    }
  }
};
export const GuestCheckout = async (data) => {
  const cart = localStorage.getItem("ecowattCart");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  if (cart && cart.length) {
    data["cart"] = cart;
    const res = await api.post(`/guest-checkout`, data, config);
    if (res.data.status === true) {
      return res.data;
    }
  }
};

export const getCalculatedDeliveryAuth = async (
  city,
  deliveryMethod,
  applyWallet
) => {
  const res = await api.post(`/delivery-fees`, {
    city: city,
    delivery_method: deliveryMethod,
    apply_wallet: applyWallet,
  });
  if (res.data.status === true) {
    return res.data.data;
  }
};
export const getCalculatedDeliveryGuest = async (city, deliveryMethod) => {
  const cart = localStorage.getItem("ecowattCart");

  if (cart && cart.length) {
    const res = await api.post(`/guest-delivery-fees`, {
      products: cart,
      city,
      delivery_method: deliveryMethod,
    });
    // console.log('getCalculatedDeliveryGuest => ', res.data)
    if (res.data.status === true) {
      return res.data.data;
    }
  }
};
export const downloadFileInvoice = async (file) => {
  const res = await api.post(`/download-invoice`, {
    file,
  });
  if (res.data.status === true) {
    return res.data;
  }
};
export const downloadFileBl = async (file) => {
  const res = await api.post(`/download-bl`, { file });
  if (res.data.status === true) {
    return res.data;
  }
};
export const downloadFileBc = async (id) => {
  const res = await api.post(`/download-bc`, { id });
  if (res.data.status === true) {
    return res.data;
  }
};
export const getInjectionSimulatorSolution = async (data) => {
  try {
    const res = await api.post(`/simulator/injection-solution`, data);
    if (res.data.status === true) {
      return res.data.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
};
