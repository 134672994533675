export default function BackToTopBtn() {
  return (
    <div className="theme-option">
      <div className="back-to-top">
        <button onClick={() => window.scrollTo({ top: 0 })}>
          <i className="fas fa-chevron-up"></i>
        </button>
      </div>
    </div>
  );
}
