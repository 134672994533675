import { useEffect, useRef, useState } from "react";
import { Search, X } from "react-feather";
import { InfinitySpin } from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { getSearchProducts } from "../../queries/queries";
import useComponentVisible from "../useComponentVisible";

const Icon = "https://via.placeholder.com/50x50";

export default function SearchProductsTablet({ toggleSearchTablet, status }) {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const debouncedSearchTerm = useDebounce(title, 500);

  const { isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const searchNodeRef = useRef();
  useOnClickOutside(searchNodeRef, () => toggleSearchTablet(false));

  const fetchProducts = async (word) => {
    setIsLoading(true);
    const res = await getSearchProducts({ title: word });
    // console.log('res => ', res)
    if (res.status === true) {
      setProducts(res?.data);
      setIsLoading(false);
    } else {
      setProducts([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 2) {
      fetchProducts(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      setProducts([]);
    }
  }, [debouncedSearchTerm]);

  const changeTitle = (word) => {
    setTitle(word);
    if (word.length >= 2) {
      setIsComponentVisible(true);
      // fetchProducts(word);
    }
    if (word.length === 0) {
      setProducts([]);
    }
  };

  const GoToSearch = (url) => {
    toggleSearchTablet(false);
    history.push(url);
  };

  return (
    <div className={`search-full ${status ? "active" : ""}`}>
      <div ref={searchNodeRef} style={{ width: "100%" }}>
        <div className="input-group">
          <a
            className="input-group-text"
            onClick={() => GoToSearch(`/products?search=${title}`)}
          >
            <Search />
          </a>
          <input
            type="text"
            onChange={(event) => changeTitle(event.target.value)}
            value={title}
            tabIndex={0}
            className="form-control search-type"
            placeholder="Je fais un achat pour.."
          />
          <span
            className="input-group-text close-search"
            onClick={() => toggleSearchTablet(false)}
          >
            <X />
          </span>
        </div>
        {isComponentVisible && (title.length >= 2 || isLoading) && (
          <div className="search-result">
            {isLoading ? (
              <div className="p-3 d-flex align-items-center justify-content-center">
                <InfinitySpin
                  type="ThreeDots"
                  color="#2A3466"
                  height={220}
                  width={220}
                  visible={isLoading}
                />
              </div>
            ) : products.length ? (
              products.map((item) => (
                <div className="single-result" key={item?.slug}>
                  <div className="product-image">
                    <Link
                      to={`/product/${item?.slug}`}
                      onClick={() => toggleSearchTablet(false)}
                    >
                      <img
                        src={item?.full_image}
                        className="img-fluid lazyload"
                        alt={item?.title}
                      />
                    </Link>
                  </div>
                  <div className="product-info">
                    <h4>
                      <Link
                        to={`/product/${item?.slug}`}
                        onClick={() => toggleSearchTablet(false)}
                      >
                        {item?.title}
                      </Link>
                    </h4>
                  </div>
                </div>
              ))
            ) : (
              <h2 className="text-center py-5">Aucun produit trouvé</h2>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
