import { useContext } from "react";
import { User } from "react-feather";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { AuthProvider } from "../../contexts/AuthContext";

export default function AccountButton() {
  const {
    isLoggedIn,
    userData,
    userId,
    logoutMutation,
    authenticationLoading,
  } = useContext(AuthProvider);

  if(authenticationLoading) return null;

  return (
    <li className="right-side onhover-dropdown">
      <div className="delivery-login-box">
        <div className="delivery-icon">
          <User />
        </div>
        <div className="delivery-detail">
          <h6>{isLoggedIn ? userData.full_name : "Guest"}</h6>
        </div>
      </div>
      <div className="onhover-div onhover-div-login">
        <ul className="user-box-name">
          {userId && isLoggedIn ? (
            <>
              <li className="product-box-contain">
                <i></i>
                <Link to={`/account`}>Mon compte</Link>
              </li>
              <li className="product-box-contain">
                <button type="button" onClick={logoutMutation}>
                  Se déconnecter
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="product-box-contain">
                <i></i>
                <Link to={`/check-order`}>Mes commandes</Link>
              </li>
              <li className="product-box-contain">
                <i></i>
                <Link to={`/login`}>Se connecter</Link>
              </li>
              <li className="product-box-contain">
                <Link to={`/register`}>S'inscrire</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </li>
  );
}
