import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// attach token in request header
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("ecowattAuthToken");

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default api;
