import { useContext } from "react";
import { CartAndWishlistProvider } from "../contexts/CartAndWishlistContext";
import BackToTopBtn from "./BackToTopBtn";
import Footer from "./Footer";
import Headers from "./Header";
import Menu from "./Menu";
import PopupProductCart from "./Product/PopupProductCart";

export default function Layout({ children }) {
  const { showPopup, closePopup } = useContext(CartAndWishlistProvider);
  return (
    <div className={`theme-color`}>
      <Headers />
      <Menu />
      <div style={{ minHeight: "100vh" }}>{children}</div>
      {showPopup ? <PopupProductCart closePopup={closePopup} /> : null}
      <Footer />
      <BackToTopBtn />
      {/* <CookieBanner /> */}
    </div>
  );
}
