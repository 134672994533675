import { Link } from "react-router-dom";

export default function MenuPrimary({ menu, toggleMenu }) {
  return (
    <div className="header-nav-middle">
      <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
        <div
          className={`offcanvas offcanvas-collapse order-xl-2 ${
            menu ? "show" : ""
          }`}
          id="primaryMenu"
        >
          <div className="offcanvas-header navbar-shadow">
            <h5>Menu</h5>
            <button
              className="btn-close lead"
              type="button"
              onClick={() => toggleMenu(false)}
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to={`/a-propos-de-nous`}>
                  À propos de nous
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/centre-d-aide`}>
                  Centre d'aide
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/contactez-nous`}>
                  Contactez-nous
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/blogs`}>
                  Blogs
                </Link>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <Link className="nav-link dropdown-toggle" to="">
                    Simulateurs
                  </Link>
                  <ul className="d-xl-none">
                    <li className="dropdown-item">
                      <Link
                        className="nav-link"
                        to="/simulateurs/note-de-calcul"
                      >
                        Note de Calcul
                      </Link>
                    </li>
                    <li title="à venir" className="dropdown-item">
                      <Link className="nav-link" to="/simulateurs/injection">
                        Simulateur Injection
                      </Link>
                    </li>
                    <li title="à venir" className="dropdown-item">
                      <Link
                        className="nav-link disabled"
                        to="/simulateurs/pompage"
                      >
                        Simulateur Pompage
                      </Link>
                    </li>
                  </ul>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/simulateurs/note-de-calcul"
                      >
                        Note de Calcul
                      </Link>
                    </li>
                    <li title="à venir">
                      <Link
                        className="dropdown-item"
                        to="/simulateurs/injection"
                      >
                        Simulateur Injection
                      </Link>
                    </li>
                    <li title="à venir">
                      <Link
                        className="dropdown-item disabled"
                        to="/simulateurs/pompage"
                      >
                        Simulateur Pompage
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <div className="dropdown">
                  <Link className="nav-link dropdown-toggle" to="">
                    Financement
                  </Link>
                  <ul className="d-xl-none">
                    <li title="à venir" className="dropdown-item">
                      <Link className="nav-link disabled" to="/">
                        Bank al yousr
                      </Link>
                    </li>
                    <li title="à venir" className="dropdown-item">
                      <Link className="nav-link disabled" to="/">
                        WafaSalaf
                      </Link>
                    </li>
                  </ul>
                  <ul className="dropdown-menu">
                    <li title="à venir">
                      <Link className="dropdown-item disabled" to="/">
                        Bank al yousr
                      </Link>
                    </li>
                    <li title="à venir">
                      <Link className="dropdown-item disabled" to="/">
                        WafaSalaf
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`offcanvas-backdrop fade ${menu ? "show" : ""}`}
          style={{ visibility: menu ? "visible" : "hidden" }}
          onClick={() => toggleMenu(false)}
        ></div>
      </div>
    </div>
  );
}
