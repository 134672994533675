import React, { useContext, useState } from "react";
import { Phone, Search } from "react-feather";
import { Link } from "react-router-dom";

import AccountButton from "./Header/AccountButton";
import CartButton from "./Header/CartButton";
import WishlistButton from "./Header/WishlistButton";

import { AuthProvider } from "../contexts/AuthContext";
import { DataProvider } from "../contexts/DataContext";
import { formatPrice } from "../lib/helpers";
import SearchProducts from "./Home/SearchProducts";
import SearchProductsTablet from "./Home/SearchProductsTablet";
import MenuCategories from "./MenuCategories";
import MenuPrimary from "./MenuPrimary";

export default function Heade() {
  const { settings } = React.useContext(DataProvider);

  const { userData, isLoggedIn } = useContext(AuthProvider);

  const [menu, setMenu] = useState(false);
  const [searchTablet, setSearchTablet] = useState(false);

  const toggleMenu = (value) => setMenu(value);
  const toggleSearchTablet = (value) => setSearchTablet(value);

  return (
    <header>
      <div className="top-nav top-header sticky-header">
        {/*  d-none d-sm-block to remove the header on phone Responsive  */}
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className={`navbar-top ${searchTablet ? "active" : ""}`}>
                {
                  <button
                    className="navbar-toggler d-xl-none d-inline navbar-menu-button"
                    type="button"
                    onClick={() => toggleMenu(true)}
                  >
                    <span className="navbar-toggler-icon">
                      <i className="fa-solid fa-bars"></i>
                    </span>
                  </button>
                }
                {settings && settings?.store_logo && (
                  <Link to="/" className="web-logo nav-logo">
                    <img
                      src={settings?.store_logo}
                      className="img-fluid lazyload"
                      alt={settings?.store_name}
                    />
                  </Link>
                )}

                <SearchProducts />

                <div className="rightside-box">
                  {
                    <SearchProductsTablet
                      toggleSearchTablet={toggleSearchTablet}
                      status={searchTablet ? "active" : ""}
                    />
                  }

                  <ul className="right-side-menu">
                    {
                      <li className="right-side">
                        <div className="delivery-login-box">
                          <div className="delivery-icon d-none d-md-block">
                            <div
                              className="search-box"
                              onClick={() => toggleSearchTablet(true)}
                            >
                              <Search />
                            </div>
                          </div>
                        </div>
                      </li>
                    }
                    <li className={`right-side d-block`}>
                      <a href={`tel:${settings?.store_phone}`}>
                        <div className="delivery-login-box easy-contact">
                          <div className="delivery-icon">
                            <Phone />
                          </div>
                          {!isLoggedIn && (
                            <div className="delivery-detail d-sm-block d-none">
                              <h6>Je passe un appel</h6>
                            </div>
                          )}
                        </div>
                      </a>
                    </li>

                    <WishlistButton />

                    <CartButton />

                    {isLoggedIn && userData?.stats && (
                      <>
                        <li className="right-side">
                          <Link
                            to="/account"
                            className="btn p-0 position-relative header-wishlist"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 453.61 356.39"
                              height="25"
                            >
                              <defs>
                                <style>
                                  {`.cls-1 {
                                    fill: #e30630;
                                  }

                                  .cls-2 {
                                    fill: #3b5370;
                                  }`}
                                </style>
                              </defs>
                              <g id="Calque_1-2" data-name="Calque 1">
                                <g>
                                  <polygon points="93.85 93.84 93.85 93.87 93.84 93.87 93.84 93.85 93.85 93.84" />
                                  <rect
                                    class="cls-1"
                                    y=".02"
                                    width="93.85"
                                    height="93.85"
                                  />
                                  <rect
                                    class="cls-1"
                                    x="226.8"
                                    y="121.1"
                                    width="93.85"
                                    height="114.18"
                                  />
                                  <polygon
                                    class="cls-2"
                                    points="453.61 0 453.61 356.39 226.81 356.39 226.81 262.54 359.76 262.54 359.76 93.85 187.69 93.85 187.69 356.39 93.84 356.39 93.84 93.87 93.85 93.87 93.85 93.84 187.69 0 453.61 0"
                                  />
                                </g>
                              </g>
                            </svg>
                            <span className="position-absolute top-0 start-100 w-auto px-1 translate-middle badge">
                              {userData?.stats?.credits}
                            </span>
                          </Link>
                        </li>
                        <li className="right-side">
                          <Link
                            to="/account"
                            className="btn p-0 position-relative header-wishlist"
                          >
                            {/* <img
                              height={20}
                              src={require("../assets/images/ecowallet.svg")}
                              alt="EcoWallet"
                            /> */}
                            <svg
                              height="25"
                              viewBox="0 0 541 541"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M535.56 62.9V61.77L535.37 62.33C523 26.11 488.65 0 448.29 0H91.97C51.56 0 17.12 26.2 4.84 62.52L4.70001 62.19V62.9C1.64001 72.07 0 81.81 0 91.97V448.29C0 499 41.26 540.26 91.97 540.26H448.29C499 540.26 540.26 499 540.26 448.29V91.97C540.26 81.81 538.61 72.07 535.56 62.9ZM497.92 448.29C497.92 475.7 475.7 497.92 448.29 497.92H91.97C64.56 497.92 42.34 475.7 42.34 448.29V253.52C88.68 318.77 161.79 362.29 243.83 369.91L267.37 371.33C273 371.67 278.64 371.58 284.25 371.05L296.38 369.91C378.43 362.29 451.58 318.77 497.92 253.47V448.28V448.29ZM42.33 91.97C42.33 64.59 64.58 42.34 91.96 42.34H448.28C475.66 42.34 497.91 64.59 497.91 91.97V156.8C469.02 258.23 375.73 328.84 270.12 328.84C164.51 328.84 71.26 258.32 42.33 156.98V91.96V91.97Z"
                                fill="#3B5370"
                              />
                              <path
                                d="M270.13 289.26C296.617 289.26 318.09 267.788 318.09 241.3C318.09 214.812 296.617 193.34 270.13 193.34C243.642 193.34 222.17 214.812 222.17 241.3C222.17 267.788 243.642 289.26 270.13 289.26Z"
                                fill="#E30630"
                              />
                              <path
                                d="M306.38 348.93H233.88V437.22H306.38V348.93Z"
                                fill="#E30630"
                              />
                            </svg>
                            <span className="position-absolute top-0 start-100 w-auto px-1 translate-middle badge">
                              {formatPrice(userData?.stats?.wallet)} DH
                            </span>
                          </Link>
                        </li>
                      </>
                    )}

                    <AccountButton />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid-lg header-bottom">
        <div className="row">
          <div className="col-12">
            <div className="header-nav">
              <MenuCategories />

              <MenuPrimary menu={menu} toggleMenu={toggleMenu} />

              {/* {(!authenticationLoading && !authenticationFetching)
                                ?
                                    (!userId ? 
                                        <Link to={`/rejoignez-nous`} className="header-nav-right">
                                            <button className="btn deal-button">
                                                <Zap />
                                                <span>Devenir un revendeur</span>
                                            </button>
                                        </Link>
                                    :
                                        <div />
                                    )
                                :
                                <div />
                            } */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
