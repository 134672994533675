import GoogleAnalytics from "./Analytics/GoogleAnalytics";
import MetaPixel from "./Analytics/MetaPixel";

export default function Analytics() {
  // const [acceptCookies] = useLocalStorage("acceptCookies", false);

  // if (!acceptCookies) return null;

  return (
    <>
      <GoogleAnalytics />
      <MetaPixel />
    </>
  );
}
