import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import ErrorSnackbar from "../components/ErrorSnackbar";
import SuccessSnackbar from "../components/SuccessSnackbar";
import { saveNewsletter } from "../queries/queries";

const launchTime = new Date("2024-03-01");
const interval = 1000;
const now = new Date();

export default function GuestWishlist() {
  const [result, setResult] = useState(null);

  const [duration, setDuration] = useState(() =>
    moment.duration(launchTime - now, "milliseconds")
  );

  // Timer Countdow
  useEffect(() => {
    const timer = setInterval(() => {
      setDuration((duration) =>
        moment.duration(duration - interval, "milliseconds")
      );
    }, interval);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResult(null);
    }, 3000);
    return () => clearTimeout(timer);
  }, [result]);

  const { handleSubmit, handleChange, handleBlur, values, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
      },
      onSubmit: (values) => {
        mutate({ email: values.email, name: values.email.split("@")[0] });
      },
    });

  const { mutate, isLoading } = useMutation(saveNewsletter, {
    onSuccess() {
      setResult(true);
    },
    onError() {
      setResult(false);
    },
    onSettled() {
      resetForm({
        name: "",
        email: "",
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>{`A venir | Ecowatt`}</title>
      </Helmet>
      <section class="coming-soon-section pt-0">
        <div class="bg-black"></div>
        <div class="container-fluid-lg w-100">
          <div class="row">
            <div class="col-lg-6"></div>

            <div class="col-xxl-5 col-xl-6 col-lg-6">
              <div class="coming-box">
                <div>
                  <div class="coming-title">
                    <h2>Nous arrivons bientôt...</h2>
                  </div>
                  <p class="coming-text">
                    Nous travaillons actuellement sur un nouveau site génial.
                    Restez à l'écoute pour plus d'informations. Abonnez-vous à
                    notre newsletter pour rester informé de nos progrès.
                  </p>

                  <div
                    class="coming-timer"
                    id="clockdiv-1"
                    data-hours="1"
                    data-minutes="2"
                    data-seconds="3"
                  >
                    <ul class="d-flex justify-content-center">
                      <li>
                        <div class="counter">
                          <div class="days">{duration.days()}</div>
                          <p>Jour</p>
                        </div>
                      </li>
                      <li>
                        <div class="counter">
                          <div class="hours">{duration.hours()}</div>
                          <p>Heure</p>
                        </div>
                      </li>
                      <li>
                        <div class="counter">
                          <div class="minutes">{duration.minutes()}</div>
                          <p>Min</p>
                        </div>
                      </li>
                      <li>
                        <div class="counter">
                          <div class="seconds">{duration.seconds()}</div>
                          <p>Sec</p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="coming-contain">
                    <form onSubmit={handleSubmit}>
                      <div class="coming-form input-group">
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Entrez votre adresse email"
                          required
                        />
                        <button
                          type="submit"
                          class="coming-button"
                          disabled={isLoading}
                        >
                          S'inscrire
                        </button>
                      </div>
                      {result === false && (
                        <ErrorSnackbar
                          message="Erreur trouvée, les données ne peuvent pas être
                          enregistrées"
                        />
                        // <span className="error-result">
                        //   Erreur trouvée, les données ne peuvent pas être
                        //   enregistrées
                        // </span>
                      )}
                      {result === true && (
                        <SuccessSnackbar message="Données enregistrées avec succès" />
                        // <span className="success-result">
                        //   Données enregistrées avec succès
                        // </span>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
