import { useContext, useRef } from "react";
import { AlignLeft } from "react-feather";
import { Link } from "react-router-dom";
import { DataProvider } from "../contexts/DataContext";
import useOnClickOutside from "../hooks/useOnClickOutside";

export default function MenuCategories({
  toggleCategories = null,
  status = null,
}) {
  const { menuCategories } = useContext(DataProvider);

  const ref = useRef();
  useOnClickOutside(ref, () => toggleCategories && toggleCategories(false));

  return (
    <div className="header-nav-left nav-categories">
      <button className="dropdown-category">
        <AlignLeft />
        <span>Toutes catégories</span>
      </button>

      <div ref={ref} className={`category-dropdown ${status ? "active" : ""}`}>
        <div className="category-title">
          <h5>Catégories</h5>
          <button
            type="button"
            className="btn p-0 close-button text-content"
            onClick={() => toggleCategories(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>

        {menuCategories && menuCategories.length && (
          <ul className="category-list">
            {menuCategories
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((item, key) => {
                const name =
                  item?.name?.[0]?.toUpperCase() +
                  item?.name?.slice(1)?.toLowerCase();
                return (
                  <li
                    className="onhover-category-list"
                    key={`menu-category-${key}`}
                  >
                    <Link
                      to={`/products?categories=${item?.slug}`}
                      className="category-name"
                    >
                      <h6>{name}</h6>
                      {item?.active_childrens &&
                      item?.active_childrens.length ? (
                        <i className="fa-solid fa-angle-right"></i>
                      ) : null}
                    </Link>
                    {item?.active_childrens && item?.active_childrens.length ? (
                      <div className="onhover-category-box">
                        <div className="list-1">
                          <ul>
                            {item?.active_childrens
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((sub, keysub) => {
                                const name =
                                  sub?.name?.[0]?.toUpperCase() +
                                  sub?.name?.slice(1)?.toLowerCase();

                                return (
                                  <li key={`menu-sub-category-${keysub}`}>
                                    <Link
                                      to={`/products?categories=${sub?.slug}`}
                                    >
                                      {name}
                                    </Link>
                                    {sub?.active_childrens &&
                                    sub?.active_childrens.length ? (
                                      <div className="onhover-category-box">
                                        <div className="list-1">
                                          <ul>
                                            {sub?.active_childrens
                                              .sort((a, b) =>
                                                a.name > b.name ? 1 : -1
                                              )
                                              .map((sub1, keysub1) => {
                                                const name =
                                                  sub1?.name[0]?.toUpperCase() +
                                                  sub1?.name
                                                    ?.slice(1)
                                                    ?.toLowerCase();
                                                return (
                                                  <li
                                                    key={`menu-sub-category-${keysub1}`}
                                                  >
                                                    <Link
                                                      to={`/products?categories=${sub1?.slug}`}
                                                    >
                                                      {name}
                                                    </Link>
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </div>
                                      </div>
                                    ) : null}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
}
