import { useEffect, useState } from "react";
import { Search } from "react-feather";
import { InfinitySpin } from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";
import { getSearchProducts } from "../../queries/queries";
import useComponentVisible from "../useComponentVisible";

const Icon = "https://via.placeholder.com/50x50";

export default function SearchProducts() {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const debouncedSearchTerm = useDebounce(title, 500);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const fetchProducts = async (word) => {
    setIsLoading(true);
    const res = await getSearchProducts({ title: word });
    if (res.status === true) {
      setProducts(res?.data);
      setIsLoading(false);
    } else {
      setProducts([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 2) {
      fetchProducts(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      setProducts([]);
    }
  }, [debouncedSearchTerm]);

  const changeTitle = (word) => {
    setTitle(word);
    if (word.length >= 2) {
      setIsComponentVisible(true);
      // fetchProducts(debouncedSearchTerm)
    }
  };

  const GoToSearch = (url) => {
    setIsComponentVisible(false);
    history.push(url);
  };

  return (
    <div className="middle-box">
      <div className="search-box" ref={ref}>
        <div className="input-group">
          <input
            onChange={(event) => changeTitle(event.target.value)}
            value={title}
            tabIndex={0}
            type="text"
            className="form-control"
            placeholder="Je fais un achat pour.."
          />
          <a
            className="btn sub-btn btn-animation"
            id="button-addon2"
            onClick={() => GoToSearch(`/products?search=${title}`)}
          >
            <Search />
          </a>
        </div>
      </div>
      {isComponentVisible && (title.length >= 2 || isLoading) && (
        <div className="search-result">
          {isLoading ? (
            <div className="p-3 d-flex align-items-center justify-content-center">
              <InfinitySpin
                type="ThreeDots"
                color="#2A3466"
                height={220}
                width={220}
                visible={isLoading}
              />
            </div>
          ) : products.length ? (
            products.map((item) => (
              <div className="single-result" key={item?.slug}>
                <div className="product-image">
                  <Link to={`/product/${item?.slug}`}>
                    <img
                      src={item?.full_image}
                      className="img-fluid lazyload"
                      alt={item?.title}
                    />
                  </Link>
                </div>
                <div className="product-info">
                  <h4>
                    <Link to={`/product/${item?.slug}`}>{item?.title}</Link>
                  </h4>
                </div>
              </div>
            ))
          ) : (
            <h2 className="text-center my-5">Aucun produit trouvé</h2>
          )}
        </div>
      )}
    </div>
  );
}
