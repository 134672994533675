import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "swiper/swiper-bundle.css";

import Loading from "./components/Loading";
import ScrollToTopOnMount from "./helpers/ScrollToTopOnMount";
import ProtectedRoute from "./protectedRoute/ProtectedRoute";

import Analytics from "./components/Analytics";
import HandleBack from "./helpers/HandleBack";
import ComingSoon from "./pages/coming-soon";
import DynamiquePage from "./pages/DynamiquePage";
import PageFailed from "./pages/PageFailed";
import PageSuccess from "./pages/PageSuccess";

const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const RegisterSeller = React.lazy(() => import("./pages/RegisterSeller"));
const Register = React.lazy(() => import("./pages/Register"));
const Page404 = React.lazy(() => import("./pages/Page404"));
const MyAccount = React.lazy(() => import("./pages/MyAccountContainer"));
const Product = React.lazy(() => import("./pages/Product"));
const Products = React.lazy(() => import("./pages/Products"));

// Page Static
const About = React.lazy(() => import("./pages/static/About"));
const Contact = React.lazy(() => import("./pages/static/Contact"));
const Faq = React.lazy(() => import("./pages/static/Faq"));
const OrderGuide = React.lazy(() => import("./pages/static/OrderGuide"));
const TrackOrder = React.lazy(() => import("./pages/static/TrackOrder"));
const DeliveryMethod = React.lazy(
  () => import("./pages/static/DeliveryMethod")
);
const PaymentMethod = React.lazy(() => import("./pages/static/PaymentMethod"));
const CGV = React.lazy(() => import("./pages/static/CGV"));
const Guide = React.lazy(() => import("./pages/static/Guide"));

// GUEST
const GuestWishlist = React.lazy(() => import("./pages/GuestWishlist"));
const Cart = React.lazy(() => import("./pages/Cart"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const PageCheckOrder = React.lazy(() => import("./pages/PageCheckOrder"));

const CalculationNote = React.lazy(
  () => import("./pages/simulators/CalculationNote")
);
const InjectionSimulator = React.lazy(
  () => import("./pages/simulators/InjectionSimulator")
);
const BlogsHome = React.lazy(() => import("./pages/BlogsHome"));
const SubmitBlog = React.lazy(() => import("./pages/SubmitBlog"));
const BlogsDetails = React.lazy(() => import("./pages/BlogsDetails"));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter basename="/">
        <ScrollToTopOnMount />
        <HandleBack />
        <Analytics />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/rejoignez-nous" component={RegisterSeller} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/page/:page" component={DynamiquePage} />
          <Route path="/product/:product" component={Product} />
          <Route path="/products" component={Products} />
          <Route path="/page-404" component={Page404} />

          <Route path="/a-propos-de-nous" component={About} />
          <Route path="/contactez-nous" component={Contact} />
          <Route path="/centre-d-aide" component={Faq} />
          <Route path="/a-venir" component={ComingSoon} />

          <ProtectedRoute path="/blogs/submit" Component={SubmitBlog} />

          <Route path="/blogs/:slug" component={BlogsDetails} />
          <Route path="/blogs" component={BlogsHome} />

          <Route path="/comment-commander" component={OrderGuide} />
          <Route path="/suivi-votre-commande" component={TrackOrder} />
          <Route path="/mode-livraison" component={DeliveryMethod} />
          <Route path="/mode-paiment" component={PaymentMethod} />
          <Route path="/CGV" component={CGV} />
          <Route path="/guide-utilisation" component={Guide} />

          <Route path="/wishlist" component={GuestWishlist} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />

          <Route path="/order-success" component={PageSuccess} />
          <Route path="/order-failed" component={PageFailed} />

          <Route path="/check-order" component={PageCheckOrder} />

          <Route
            path="/simulateurs/note-de-calcul"
            component={CalculationNote}
          />
          <Route path="/simulateurs/injection" component={InjectionSimulator} />

          <ProtectedRoute path="/account/:key?" Component={MyAccount} />
          <Route path="*" component={Page404} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
