import { useEffect } from "react";
import ReactGA from "react-ga4";

export default function GoogleAnalytics() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }, []);

  return null;
}
