import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Mail, Phone } from "react-feather";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { object, string } from "yup";
import { DataProvider } from "../contexts/DataContext";
import { saveNewsletter } from "../queries/queries";

export default function Footer() {
  const { settings } = useContext(DataProvider);
  const [result, setResult] = useState(null);

  const { mutateAsync } = useMutation(saveNewsletter, {
    onSuccess() {
      setResult(true);
    },
    onError() {
      setResult(false);
    },
  });

  useEffect(() => {
    if (result != null) {
      const timer = setTimeout(() => {
        setResult(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [result]);

  const ValidationSchemaForm = object({
    email: string().email("Email invalide").required("Required"),
  });

  return (
    <footer className="section-t-space">
      <div className="container-fluid-lg">
        <div className="main-footer section-b-space section-t-space">
          <div className="row g-md-4 g-3">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="footer-logo">
                {settings && settings?.store_white && (
                  <div className="theme-logo">
                    <Link to="/">
                      <img
                        src={settings?.store_white}
                        className="lazyload"
                        alt={settings?.store_name ?? ""}
                      />
                    </Link>
                  </div>
                )}

                <div className="mt-3">
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={ValidationSchemaForm}
                    onSubmit={async (values, actions) => {
                      await mutateAsync(values);
                      actions.resetForm({
                        values: {
                          email: "",
                        },
                      });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="form-group">
                            <div className="relative">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Votre adresse email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {/* <i className="fa-solid fa-envelope arrow"></i> */}
                              <span className="error-form">
                                {errors.email && touched.email && errors.email}
                              </span>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="p-3 mt-2 py-2 sub-btn btn-animation"
                            disabled={isSubmitting}
                          >
                            <span>S'abonner</span>
                            {/* <i className="fa-solid fa-arrow-right icon"></i> */}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                  {result === false && (
                    <span className="error-result">
                      Erreur trouvée, les données ne peuvent pas être
                      enregistrées
                    </span>
                  )}
                  {result === true && (
                    <span className="success-result">
                      Données enregistrées avec succès
                    </span>
                  )}
                </div>

                {/* <div className="footer-logo-contain">
                  {(settings && settings?.store_description) && <p>{settings?.store_description}</p>}
                </div> */}
              </div>
            </div>

            <div className="col-xl-4 col-lg-5 col-md-4 col-sm-6">
              <div className="footer-title">
                <h4>Nos agences</h4>
              </div>
              <div className="footer-contact">
                <ul className="address mt-3">
                  {settings && settings?.store_address && (
                    <li>
                      <div
                        className={`adress_html`}
                        dangerouslySetInnerHTML={{
                          __html: settings?.store_address,
                        }}
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-sm-6">
              <div className="footer-title">
                <h4>Liens utiles</h4>
              </div>
              <div className="footer-contain">
                <ul>
                  <li>
                    <Link to="/comment-commander" className="text-content">
                      Comment Commander
                    </Link>
                  </li>
                  <li>
                    <Link to="/suivi-votre-commande" className="text-content">
                      Suivi Votre Commande
                    </Link>
                  </li>
                  <li>
                    <Link to="/mode-livraison" className="text-content">
                      Mode de livraison
                    </Link>
                  </li>
                  <li>
                    <Link to="/mode-paiment" className="text-content">
                      Mode de paiment
                    </Link>
                  </li>
                  <li>
                    <Link to="/devenir-revendeur" className="text-content">
                      Devenir Revendeur
                    </Link>
                  </li>
                  <li>
                    <Link to="/CGV" className="text-content">
                      Conditions générales de vente (CGV)
                    </Link>
                  </li>
                  <li>
                    <Link to="/guide-utilisation" className="text-content">
                      Guide d'utlisation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="footer-title">
                <h4>Contactez-nous</h4>
              </div>

              <div className="footer-contact">
                <ul>
                  {settings && settings?.store_email && (
                    <li>
                      <div className="footer-number">
                        <Mail />
                        <div>
                          <h5>{settings?.store_email}</h5>
                        </div>
                      </div>
                    </li>
                  )}
                  {settings && settings?.store_address && (
                    <li>
                      <div className="footer-number">
                        <Phone />
                        <div>
                          <h5>{settings?.store_phone}</h5>
                        </div>
                      </div>
                    </li>
                  )}

                  {settings &&
                    (settings?.android_link || settings?.apple_link) && (
                      <li className="social-app mb-0"></li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="sub-footer section-small-space">
          {settings && settings?.copyright && (
            <div className="reserve">
              <h6 className="text-content">{settings?.copyright}</h6>
            </div>
          )}

          {settings && settings?.payment_images && (
            <div className="payment">
              <img src={settings?.payment_images} className="lazyload" alt="" />
            </div>
          )}

          <div className="social-link">
            {/* <h6 className="text-content">Stay connected :</h6> */}
            <ul>
              {settings && settings?.sm_facebook && (
                <li>
                  <a
                    href={settings?.sm_facebook}
                    target="_blank"
                    rel="noreferrer"
                    style={{ backgroundColor: "#3b5998" }}
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
              )}
              {settings && settings?.sm_instagram && (
                <li>
                  <a
                    href={settings?.sm_instagram}
                    target="_blank"
                    rel="noreferrer"
                    style={{ backgroundColor: "#bd32a2" }}
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
              )}
              {settings && settings?.sm_linkedin && (
                <li>
                  <a
                    href={settings?.sm_linkedin}
                    target="_blank"
                    rel="noreferrer"
                    style={{ backgroundColor: "#0070ac" }}
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
              )}
              {settings && settings?.sm_whatsapp && (
                <li>
                  <a
                    href={settings?.sm_whatsapp}
                    target="_blank"
                    rel="noreferrer"
                    style={{ backgroundColor: "#25D366" }}
                  >
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
